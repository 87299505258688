<template>
  <div class="container">Vtime Web</div>
</template>

<script>
export default {
  data() {
    return {}
  }
};
</script>

<style scoped>
.container {
  color: #fff;
  background-color: rgb(142, 185, 165);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 56px;
}
</style>