<template>
  <div class="container">
    <div class="box">
      <span class="title">登录</span>
      <div class="content">
        <span class="subTitle">帐号</span>
        <el-input
          v-model="account"
          placeholder="请输入帐号"
          class="input"
        ></el-input>
        <span class="subTitle">密码</span>
        <el-input
          v-model="password"
          type="password"
          placeholder="请输入密码"
          class="input"
        ></el-input>
      </div>
      <el-button type="success" class="button" @click="auth">登录</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  mounted() {},
  data() {
    return {
      account: "",
      password: "",
    };
  },
  methods: {
    auth() {
      console.log(this.account, this.password);
      if (this.account == "vargo" && this.password == "Vargo2021@") {
        localStorage.setItem('account', this.account);
        this.$router.push("/home");
      } else {
        this.$message({
          type: "error",
          message: `帐号或密码不正确!`,
          duration: 10000,
          showClose: true,
        });
      }
    },
  },
};
</script>

<style scoped>
.input {
  margin-bottom: 10px;
  margin-top: 10px;
}

.button {
  width: 40%;
  display: block;
  margin: 0 auto;
}

.container {
  background-color: rgb(142, 185, 165);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.content {
  margin-top: 40px;
  text-align: left;
  margin-bottom: 50px;
}

.title {
  width: 100%;
  text-align: center;
  font-size: 20px;
}

.box {
  padding: 20px;
  position: relative;
  height: 360px;
  width: 360px;
  border-radius: 20px;
  background-color: #fff;
  filter: drop-shadow(0 0 0.75rem rgb(0, 0, 0));
}
</style>
