<template>
  <div class="container">
    <el-row>
      <div style="font-size: 24px">新闻管理</div>
    </el-row>
    <el-row class="buttons">
      <el-button type="primary" @click="addClick()">新增</el-button>
    </el-row>
    <el-table :data="tableData" stripe v-loading="loading" style="width: 100%">
      <el-table-column
        type="index"
        label="序号"
        width="80"
        align="center"
      ></el-table-column>
      <el-table-column prop="title" label="标题" width="180" align="center">
      </el-table-column>
      <el-table-column prop="url" label="缩略图" width="180" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.url.replace('/', '').length < 1">暂无</div>
          <el-image
            v-else
            style="width: 100px; height: 100px"
            :src="scope.row.url"
            :fit="'contain'"
            @click="previewImg(scope.row)"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="description" label="简介" align="center">
      </el-table-column>
      <el-table-column prop="date" label="发布日期" width="180" align="center">
      </el-table-column>
      <el-table-column label="操作" width="180" align="center">
        <template slot-scope="scope">
          <el-button @click="previewClick(scope.row)" type="text" size="small"
            >查看</el-button
          >
          <el-button @click="editClick(scope.row)" type="text" size="small"
            >编辑</el-button
          >
          <el-button
            @click="deleteClick(scope.row)"
            type="text"
            size="small"
            style="color: red"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 30px; overflow: hidden; margin-top: 20px">
      <el-pagination
        style="float: right"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        background
        :page-size="pagesize"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    console.log(this.tableData.length);
    this.$http
      .get(`${this.$baseUrl}/news/list?page=1&size=10`)
      .then((response) => {
        this.tableData = response.data.result.records;
        this.total = response.data.result.total;
        this.loading = false;
        console.log(this.tableData);
      });
  },
  data() {
    return {
      loading: true,
      currentPage: 1,
      total: 0,
      pagesize: 10,
      tableData: [],
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
      this.loading = true;
      this.$http
        .get(`${this.$baseUrl}/news/list?page=${this.currentPage}&size=10`)
        .then((response) => {
          this.tableData = response.data.result.records;
          this.total = response.data.result.total;
          this.loading = false;
          console.log(this.tableData);
        });
    },
    previewImg(row) {
      row.url && this.$hevueImgPreview(row.url);
    },
    deleteClick(item) {
      this.$http
        .delete(`${this.$baseUrl}/news/delete?id=${item.id}`)
        .then((res) => {
          if (res.data.code !== 200) {
            this.$message({
              type: "warning",
              message: `删除失败!`,
              duration: 10000,
              showClose: true,
            });
          } else {
            this.$message({
              type: "success",
              message: `删除成功!`,
              duration: 10000,
              showClose: true,
            });
            this.loading = true;
            if (this.tableData.length === 1) {
              this.currentPage = this.currentPage - 1;
            }
            this.$http
              .get(
                `${this.$baseUrl}/news/list?page=${this.currentPage}&size=10`
              )
              .then((response) => {
                this.tableData = response.data.result.records;
                this.total = response.data.result.total;
                this.loading = false;
                console.log(this.tableData);
              });
          }
        });
    },
    editClick(item) {
      console.log(item);
      this.$router.push({
        name: "NewsDetail",
        params: {
          item: item,
          new: false,
        },
      });
    },
    previewClick(item) {
      console.log(item);
      this.$router.push({
        name: "NewsDetail",
        params: {
          item: item,
          new: false,
          preview: true,
        },
      });
    },
    addClick() {
      this.$router.push({
        name: "NewsDetail",
        params: {
          new: true,
        },
      });
    },
  },
};
</script>

<style scoped>
.buttons {
  float: right;
  margin-bottom: 20px;
}
.container {
  user-select: none;
  color: #fff;
  background-color: rgb(142, 185, 165);
  width: calc(100% - 40px);
  min-height: calc(100% - 40px);
  padding: 20px;
}
</style>

<style>
.image-slot {
  background: rgb(231, 231, 231);
  color: rgb(185, 185, 185);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100px;
}
</style>