<template>
  <el-container style="height: 100vh">
    <el-header
      :style="{display: showDetail ? 'flex' : 'none'}"
      style="
        text-align: center;
        font-size: 12px;
        z-index: 2;
        user-select: none;
        position: relative;
        display: flex;
        justify-content: center;
      "
      height="50px"
    >
      <transition name="fade" mode="out-in">
        <i
          :class="'el-icon-arrow-left'"
          style="
            position: absolute;
            left: 20px;
            line-height: 50px;
            font-size: 30px;
            cursor: pointer;
          "
          @click="goBack"
          v-show="showDetail"
        ></i>
      </transition>
      <!-- <transition name="fade" mode="out-in">
        <span style="font-size: 18px; position: absolute" v-show="!showDetail"
          >新闻中心</span
        >
      </transition> -->
      <transition name="fade" mode="out-in">
        <span style="font-size: 18px; position: absolute" v-show="showDetail"
          >详情</span
        >
      </transition>
    </el-header>
    <el-main v-show="!showDetail" v-infinite-scroll="load">
      <el-card
        :body-style="{ padding: '20px', display: 'flex' }"
        style="border-radius: 0px"
        shadow="hover"
        v-for="item in newsData"
        :key="item.id"
        @click.native="showItem(item)"
      >
        <el-image style="width: 25%" :src="item.url" :fit="'cover'"
          ><div slot="error" class="image-slot">暂无图片</div>
        </el-image>
        <div style="padding-left: 20px; width: 75%">
          <div style="font-size: 18px">{{ item.title }}</div>
          <div style="font-size: 12px">{{ item.date }}</div>
          <div style="font-size: 14px" class="preview">
            {{ item.description }}
          </div>
        </div>
      </el-card>
    </el-main>
    <el-main v-if="showDetail" style="padding: 20px">
      <div style="font-size: 26px; font-weight: 700">{{ current.title }}</div>
      <div style="font-size: 12px">{{ current.date }}</div>
      <div v-html="current.content"></div>
    </el-main>
  </el-container>
</template>

<script>
export default {
  mounted() {
    this.$http
      .get(`${this.$baseUrl}/news/list?page=1&size=20`)
      .then((response) => {
        this.newsData = response.data.result.records;
        console.log(this.newsData);
      });
  },
  data() {
    return {
      showDetail: false,
      currentPage: 1,
      current: {},
      end: false,
      newsData: [],
    };
  },
  methods: {
    load() {
      if (!this.end) {
        this.currentPage += 1;
        this.$http
          .get(`${this.$baseUrl}/news/list?page=${this.currentPage}&size=20`)
          .then((response) => {
            if (response.data.result.records !== null) {
              this.newsData = this.newsData.concat(
                response.data.result.records
              );
              console.log(this.newsData);
            } else {
              this.end = true;
            }
          });
      }
    },
    goBack() {
      this.showDetail = false;
    },
    showItem(item) {
      this.showDetail = true;
      this.current = item;
      console.log(item);
    },
  },
};
</script>

<style>
img {
  max-width: 100% !important;
}
iframe {
  min-width: 100% !important;
  max-width: 100% !important;
  min-height: 300px;
}
</style>

<style scoped>

/* table 样式 */
.el-main >>> table {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
.el-main >>> table td,
.el-main >>> table th {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 3px 5px;
}
.el-main >>> table th {
  border-bottom: 2px solid #ccc;
  text-align: center;
}

/* blockquote 样式 */
.el-main >>> blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  font-size: 100%;
  background-color: #f1f1f1;
}

/* code 样式 */
.el-main >>> code {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 3px 5px;
  margin: 0 3px;
}
.el-main >>> pre code {
  display: block;
}

/* ul ol 样式 */
.el-main >>> ul,
.el-main >>> ol {
  margin: 10px 0 10px 20px;
}
.preview {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.el-main {
  padding: 0;
  margin: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.12);
}
.el-menu {
  border-right: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgb(0, 0, 0);
}
.image {
  width: 100px;
  display: block;
}
</style>
<style>
.el-header {
  background-color: #ffffff;
  color: rgb(99, 99, 99);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.12);
  line-height: 50px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>