<template>
  <div class="container">
    <div>签到打卡</div>
    <div href="#" class="BUTTON_WSG" @click="checkIn">
      {{ buttonType[status] }}
    </div>
    <div class="address">
      <i class="el-icon-position"></i>{{ address
      }}<i
        class="el-icon-refresh"
        v-show="address !== '定位中...'"
        @click="getPos"
        style="cursor: pointer"
      ></i>
    </div>
    <div class="clock">
      {{ curTime }}
    </div>
    <baidu-map class="bm-view" @ready="handler"> </baidu-map>
  </div>
</template>

<script>
export default {
  data() {
    return {
      curTime: "",
      buttonType: {
        1: "上班打卡",
        2: "外勤打卡",
        3: "下班打卡",
      },
      status: 1,
      address: "定位中...",
      center: {},
      BMap: null,
    };
  },
  mounted() {
    requestAnimationFrame(this.display_time);
    document.body.addEventListener("touchstart", function () {});
  },
  methods: {
    handler({ BMap, map }) {
      console.log(BMap, map);
      this.BMap = BMap;
      this.getPos();
    },
    // ipCheck(result) {
    //   const geocoder = new this.BMap.Geocoder();
    //   console.log(result);
    //   geocoder.getLocation(result.center, (rs) => {
    //     console.log(rs);
    //     this.address = rs.address;
    //   });
    // },
    // translateCallback(data) {
    // const geocoder = new this.BMap.Geocoder();
    // geocoder.getLocation(data.points[0], (rs) => {
    //   console.log(rs);
    //   this.address = rs.address;
    // });
    // },
    getPos() {
      this.address = "定位中...";
      const geolocation = new this.BMap.Geolocation();
      const geocoder = new this.BMap.Geocoder();
      // const convertor = new this.BMap.Convertor();
      // console.log(convertor);
      // let myCity = new this.BMap.LocalCity();
      // myCity.get(this.ipCheck);
      geolocation.getCurrentPosition(
        (r) => {
          console.log(r);
          this.center = { lng: r.longitude, lat: r.latitude };

          // let pointArr = [r.point];
          // convertor.translate(pointArr, 5, 5, this.translateCallback);
          // console.log(newPoint);

          geocoder.getLocation(r.point, (rs) => {
            console.log(rs);
            this.address = rs.surroundingPois[0].title;
          });
        },
        { enableHighAccuracy: true }
      );
    },
    display_time() {
      let now = new Date();
      let hh = now.getHours();
      let mm = now.getMinutes();
      let ss = now.getSeconds();
      hh = this.check(hh);
      mm = this.check(mm);
      ss = this.check(ss);
      this.curTime = hh + ":" + mm + ":" + ss;
      requestAnimationFrame(this.display_time);
    },
    check(a) {
      if (a < 10) return "0" + a;
      else return a;
    },
    checkIn() {
      this.$alert("打卡成功", "提示", {
        confirmButtonText: "确定",
        showClose: false,
        closeOnClickModal: true,
        callback: () => {
          // this.$message({
          //   type: "info",
          //   message: `action: ${action}`,
          // });
          switch (this.status) {
            case 1:
            case 2:
              this.status = 3;
              break;
            case 3:
              this.status = 1;
              break;
          }
        },
      });
    },
  },
};
</script>

<style scoped>
/* .bm-view {
  width: 100%;
  height: 300px;
} */
.address {
  font-size: 24px;
}
.clock {
  font-family: Consolas, Monaco, monospace;
}
.container {
  user-select: none;
  color: #fff;
  background-color: rgb(142, 185, 165);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  font-size: 56px;
}
.BUTTON_WSG {
  margin: 40px 0;
  height: 200px;
  width: 200px;
  background: #3d94f6;
  background-image: -webkit-gradient(to bottom, #3d94f6, #1e62d0);
  background-image: -o-linear-gradient(top, #3d94f6, #1e62d0);
  background-image: -ms-linear-gradient(top, #3d94f6, #1e62d0);
  background-image: -moz-linear-gradient(top, #3d94f6, #1e62d0);
  background-image: -webkit-linear-gradient(top, #3d94f6, #1e62d0);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  color: #ffffff;
  font-size: 40px;
  font-weight: 100;
  line-height: 200px;
  padding: 40px;
  -webkit-box-shadow: 1px 1px 20px 0 #000000;
  -moz-box-shadow: 1px 1px 20px 0 #000000;
  box-shadow: 1px 1px 20px 0 #000000;
  text-shadow: 1px 1px 20px #000000;
  /* border: solid #337fed 1px; */
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  text-align: center;
}

.BUTTON_WSG:active {
  /* border: solid #337fed 1px; */
  background: #1e62d0;
  background-image: -webkit-gradient(to bottom, #1e62d0, #3d94f6);
  background-image: -o-linear-gradient(top, #1e62d0, #3d94f6);
  background-image: -ms-linear-gradient(top, #1e62d0, #3d94f6);
  background-image: -moz-linear-gradient(top, #1e62d0, #3d94f6);
  background-image: -webkit-linear-gradient(top, #1e62d0, #3d94f6);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  text-decoration: none;
}
</style>