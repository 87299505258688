<template>
  <div class="container">
    <div class="card">
      <el-row>
        <div style="font-size: 24px; margin-bottom: 20px">新闻详情</div>
      </el-row>
      <el-form
        :label-position="'top'"
        label-width="80px"
        :model="formData"
        :rules="rules"
        ref="form"
      >
        <el-form-item label="标题" prop="title">
          <el-input
            v-model="formData.title"
            :readonly="preview"
            maxlength="100"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="发布日期" prop="date">
          <el-date-picker
            v-model="formData.date"
            type="datetime"
            :readonly="preview"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="缩略图" prop="url">
          <el-upload
            :disabled="preview"
            ref="upload"
            :action="uploadUrl"
            :class="{ disabled: isMax }"
            list-type="picture-card"
            :auto-upload="false"
            accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PNG,.GIF,.BMP"
            :limit="1"
            :file-list="fileList"
            :on-remove="handleRemove"
            :on-success="success"
            :on-error="error"
            :beforeUpload="beforeUpload"
            :on-change="change"
          >
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="新闻简介">
          <el-input
            v-model="formData.description"
            :readonly="preview"
            show-word-limit
            maxlength="100"
          ></el-input>
        </el-form-item>
        <el-form-item label="新闻内容">
          <div id="editor"></div>
        </el-form-item>
      </el-form>
      <el-row type="flex" justify="end">
        <el-button @click="goBack">返回</el-button>
        <el-button
          type="primary"
          @click="submitForm"
          v-show="!preview"
          style="width: 200px"
          >确认</el-button
        >
      </el-row>
    </div>
  </div>
</template>

<script>
import E from "wangeditor";
export default {
  mounted() {
    console.log(this.$route.params.item);
    if (
      (this.$route.params.item === undefined ||
        this.$route.params.item === null) &&
      !this.$route.params.new
    ) {
      this.$router.push("NewsManage");
    } else {
      this.editor = new E("#editor");
      this.editor.config.uploadImgServer = `${this.$baseUrl}/pic/upload`;
      this.editor.config.uploadImgMaxLength = 1;
      this.editor.config.zIndex = 5;
      this.editor.config.uploadFileName = "file";
      this.editor.create();
      if (!this.$route.params.new) {
        this.formData = this.$route.params.item;
        this.editor.txt.html(this.formData.content);
        if (this.$route.params.item.url.replace("/", "").length > 0) {
          this.fileList.push({
            name: "",
            url: this.$route.params.item.url,
          });
          this.isMax = true;
          this.notUploaded = false;
        }
      }
    }
    if (this.$route.params.preview) {
      this.preview = true;
      this.editor.disable();
    }
  },
  data() {
    return {
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        date: [{ required: true, message: "请选择日期", trigger: "change" }],
      },
      isMax: false,
      preview: false,
      notUploaded: true,
      uploadUrl: `${this.$baseUrl}/pic/upload`,
      fileList: [],
      editor: null,
      formData: {
        title: "",
        date: "",
        description: "",
        url: "",
        content: "",
      },
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    submitForm() {
      if (this.isMax && this.notUploaded) {
        this.$refs["upload"].submit();
      } else {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.formData.content = this.editor.txt.html();
            if (this.$route.params.new) {
              this.$http
                .post(`${this.$baseUrl}/news/save`, {
                  title: this.formData.title,
                  date: this.formData.date,
                  description: this.formData.description,
                  content: this.formData.content,
                  url: this.formData.url,
                })
                .then((res) => {
                  if (res.data.code !== 200) {
                    this.$message({
                      type: "warning",
                      message: `添加失败!`,
                      duration: 10000,
                      showClose: true,
                    });
                  } else {
                    this.$message({
                      type: "success",
                      message: `添加成功!`,
                      duration: 10000,
                      showClose: true,
                    });
                    this.goBack();
                  }
                }).catch(() => {
                  this.$message({
                    type: "warning",
                    message: `添加失败!`,
                    duration: 10000,
                    showClose: true,
                  });
                });
            } else {
              this.$http
                .put(`${this.$baseUrl}/news/modify`, {
                  id: this.$route.params.item.id,
                  title: this.formData.title,
                  date: this.formData.date,
                  description: this.formData.description,
                  content: this.formData.content,
                  url: this.formData.url,
                })
                .then((res) => {
                  if (res.data.code !== 200) {
                    this.$message({
                      type: "warning",
                      message: `修改失败!`,
                      duration: 10000,
                      showClose: true,
                    });
                  } else {
                    this.$message({
                      type: "success",
                      message: `修改成功!`,
                      duration: 10000,
                      showClose: true,
                    });
                    this.goBack();
                  }
                })
                .catch(() => {
                  this.$message({
                    type: "warning",
                    message: `修改失败!`,
                    duration: 10000,
                    showClose: true,
                  });
                });
            }
          }
        });
      }
      console.log(this.formData);
    },
    beforeUpload() {
      // let _this = this;
      // return new Promise((resolve, reject) => {
      //   let isLt2M = file.size / 1024 / 1024 < 10; // 判定图片大小是否小于10MB
      //   if (!isLt2M) {
      //     reject();
      //   }
      //   let image = new Image(),
      //     resultBlob = "";
      //   image.src = URL.createObjectURL(file);
      //   image.onload = () => {
      //     // 调用方法获取blob格式，方法写在下边
      //     resultBlob = _this.compressUpload(image, file);
      //     resolve(resultBlob);
      //   };
      //   image.onerror = () => {
      //     reject();
      //   };
      // });
    },
    /* 图片压缩方法-canvas压缩 */
    compressUpload(image, file) {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      // let initSize = image.src.length;
      let { width } = image,
        { height } = image;
      canvas.width = width;
      canvas.height = height;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0, width, height);
      // 进行最小压缩0.1
      let compressData = canvas.toDataURL(file.type || "image/jpeg", 0.1);
      // 压缩后调用方法进行base64转Blob，方法写在下边
      let blobImg = this.dataURItoBlob(compressData);
      return blobImg;
    },
    /* base64转Blob对象 */
    dataURItoBlob(data) {
      let byteString;
      if (data.split(",")[0].indexOf("base64") >= 0) {
        byteString = atob(data.split(",")[1]);
      } else {
        byteString = unescape(data.split(",")[1]);
      }
      let mimeString = data.split(",")[0].split(":")[1].split(";")[0];
      let ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    },

    handleRemove(file, fileList) {
      if (fileList.length !== 1) {
        this.formData.url = "/";
        this.notUploaded = true;
        this.isMax = false;
      }
      console.log(file, fileList);
    },
    change(file, fileList) {
      if (fileList.length === 1) {
        this.isMax = true;
      }
      console.log(file, fileList);
    },
    error(file, fileList) {
      if (fileList.length === 1) {
        this.isMax = true;
      } else {
        this.isMax = false;
      }
      console.log(file, fileList);
    },
    success(response, file, fileList) {
      this.formData.url = response.data[0].url;
      this.isMax = false;
      this.notUploaded = false;
      this.submitForm();
      console.log(response, file, fileList);
      console.log("success");
      console.log(this.formData);
    },
  },
};
</script>

<style>
.disabled .el-upload--picture-card {
  display: none;
}
</style>


<style scoped>
.buttons {
  float: right;
  margin-bottom: 20px;
}
.card {
  width: calc(100% - 40px);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  background: white;
  padding: 20px;
}
.container {
  user-select: none;
  color: rgb(27, 27, 27);
  background-color: rgb(142, 185, 165);
  width: calc(100% - 40px);
  min-height: calc(100% - 40px);
  padding: 20px;
}
</style>