import Vue from "vue";
import VueRouter from "vue-router";
import Login from '../views/Login.vue';
import NewsList from '../views/NewsList.vue';
import HomePage from '../components/HomePage.vue';
import CheckIn from '../components/CheckIn.vue';
import NewsManage from '../components/NewsManage.vue';
import NewsDetail from '../components/NewsDetail.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      title: '登录'
    }
  },
  {
    path: '/newsList',
    name: 'NewsList',
    component: NewsList,
    meta: {
      title: '新闻中心'
    }
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
    children: [
      {
        path: '/checkIn',
        name: 'CheckIn',
        component: CheckIn,
        meta: {
          title: '签到打卡'
        }
      },
      {
        path: '/homePage',
        name: 'HomePage',
        component: HomePage,
        meta: {
          title: '首页'
        }
      },
      {
        path: '/newsManage',
        name: 'NewsManage',
        component: NewsManage,
        meta: {
          title: '新闻管理'
        }
      },
      {
        path: '/newsDetail',
        name: 'NewsDetail',
        component: NewsDetail,
        meta: {
          title: '新闻详情'
        }
      }
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router;
