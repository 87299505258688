import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import router from "./router";
import VueAxios from "vue-axios";
import ElementUI from "element-ui";
import BaiduMap from "vue-baidu-map";
import hevueImgPreview from "hevue-img-preview";
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
import "element-ui/lib/theme-chalk/index.css";
// import 'element-ui/lib/theme-chalk/base.css';

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: "1DIXzjHwZlhkIELLX37od36jRT4ss2Vu",
});

Vue.prototype.$baseUrl = "https://smartcard.iradar.cn";

Vue.component(CollapseTransition.name, CollapseTransition);
Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(ElementUI);
Vue.use(hevueImgPreview, {
  clickMaskCLose: true,
});

router.beforeEach((to, from, next) => {
  if (to.name === "NewsList") {
    next();
  } else if (
    to.name !== "Login" &&
    !localStorage.getItem("account").length > 0
  ) {
    next({ name: "Login" });
  } else next();
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
